export default defineNuxtPlugin(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });

    const gtmScriptTag = document.createElement('script');
    gtmScriptTag.async = true;
    gtmScriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-PB5DQWSL';
    document.head.appendChild(gtmScriptTag);
});
