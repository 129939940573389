<template>

  <ClientOnly>
    <!-- 09f917c957da2608 -->
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PB5DQWSL" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
  </ClientOnly>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.loadPinterestScript();
  },
  methods: {
    loadPinterestScript() {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = "//assets.pinterest.com/js/pinit.js";
      script.setAttribute('data-pin-do', 'buttonPin');
      script.setAttribute('data-pin-hover', 'true');
      script.setAttribute('data-pin-round', 'true');
      document.body.appendChild(script);
    }
  }
}
</script>

<style>
.animatable-img {
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}

a:hover .animatable-img {
  transform: scale(1.03);
}

.secondary-text {
  color: #6E6E73;
}

.custom-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 256px;
  /* Fallback height */
  overflow: hidden;
}

.custom-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* Center the image within the element */
}

body {
  @apply bg-white dark:bg-black;
}

a {
  @apply text-primary-200 no-underline hover:underline;
}

.card a {
  @apply text-black dark:text-white no-underline;
}

figcaption a {
  @apply text-black dark:text-white no-underline;
}

.no-clickable-links a {
  pointer-events: none;
  color: inherit;
  /* Optional: to make the links appear like normal text */
  text-decoration: none;
  /* Optional: to remove underline from the links */
}
</style>