import { initializeApp, getApps } from 'firebase/app'
import { getAnalytics, isSupported } from "firebase/analytics"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: 'AIzaSyBlUnXhAsYu56NGkkV6O2-E6lVGVHz8dw0',
    authDomain: 'wafa-blog.firebaseapp.com',
    projectId: 'wafa-blog',
    storageBucket: 'wafa-blog.appspot.com',
    messagingSenderId: '1058805572115',
    appId: '1:1058805572115:web:0b79cf151f27e6b12795e6',
    measurementId: 'G-80CQGL8VSJ'
};

if (!getApps().length) {
    initializeApp(firebaseConfig)
}

export async function analytics() {
    const supported = await isSupported();
    if (supported) {
        return getAnalytics();
    } else {
        console.log('Firebase Analytics is not supported in this environment');
        return null;
    }
}

export const db = getFirestore()
export const storage = getStorage()

