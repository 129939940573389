import { default as aboutmNGXxWicpZMeta } from "/Users/mlouli/Sites/wafa-blog/pages/about.vue?macro=true";
import { default as _91id_93iCu2brobfxMeta } from "/Users/mlouli/Sites/wafa-blog/pages/articles/[id].vue?macro=true";
import { default as _91id_933i2QTz5XmaMeta } from "/Users/mlouli/Sites/wafa-blog/pages/articles/categories/[id].vue?macro=true";
import { default as indexSw6QPz0RibMeta } from "/Users/mlouli/Sites/wafa-blog/pages/articles/index.vue?macro=true";
import { default as grammes_45millilitres6XumhOpGPxMeta } from "/Users/mlouli/Sites/wafa-blog/pages/convertisseurs/grammes-millilitres.vue?macro=true";
import { default as indexjbk8X7toPiMeta } from "/Users/mlouli/Sites/wafa-blog/pages/index.vue?macro=true";
import { default as noticeqoBS0avGFcMeta } from "/Users/mlouli/Sites/wafa-blog/pages/legal/notice.vue?macro=true";
import { default as privacys7hkUlt4VXMeta } from "/Users/mlouli/Sites/wafa-blog/pages/legal/privacy.vue?macro=true";
import { default as termsnchEHB6fHBMeta } from "/Users/mlouli/Sites/wafa-blog/pages/legal/terms.vue?macro=true";
import { default as _91id_93kgSZwcEwhHMeta } from "/Users/mlouli/Sites/wafa-blog/pages/recipes/[id].vue?macro=true";
import { default as _91id_93NCOMSxfI0lMeta } from "/Users/mlouli/Sites/wafa-blog/pages/recipes/collections/[id].vue?macro=true";
import { default as component_45stubEryUQn5bCeMeta } from "/Users/mlouli/Sites/wafa-blog/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubEryUQn5bCe } from "/Users/mlouli/Sites/wafa-blog/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: "articles-categories-id",
    path: "/articles/categories/:id()",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/articles/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "convertisseurs-grammes-millilitres",
    path: "/convertisseurs/grammes-millilitres",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/convertisseurs/grammes-millilitres.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-notice",
    path: "/legal/notice",
    meta: noticeqoBS0avGFcMeta || {},
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/legal/notice.vue").then(m => m.default || m)
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    meta: privacys7hkUlt4VXMeta || {},
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: "legal-terms",
    path: "/legal/terms",
    meta: termsnchEHB6fHBMeta || {},
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/legal/terms.vue").then(m => m.default || m)
  },
  {
    name: "recipes-id",
    path: "/recipes/:id()",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/recipes/[id].vue").then(m => m.default || m)
  },
  {
    name: "recipes-collections-id",
    path: "/recipes/collections/:id()",
    component: () => import("/Users/mlouli/Sites/wafa-blog/pages/recipes/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/ngSH4RFgB0HufiLoUiJc/comment-conserver-ses-crepes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/6gnv2gnjreQd6qbL4em1/cookies-au-chocolat-facon-levain-bakery",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/7Rs0FUsFD6mWJPXXfpGM/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/7VQiNRLBoGlY7OqqYXF4/fudge-au-chocolat",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/HEt3A8hLL7FWgguPDn14/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/HEt3A8hLL7FWgguPDn14/banana-bread-au-chocolat-gateau-a-la-banane",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/M1N9eOdHxfWvtayY70PH/gateau-au-yaourt",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/M1N9eOdHxfWvtayY70PH/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/Oeuecy0C6aDaC7sAP0xM/beignets-aux-pommes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/Oeuecy0C6aDaC7sAP0xM/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/l1GdUcFIHAqWhER6uRxI/cookies-au-chocolat-et-aux-noisettes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/vUT5LFCs7oaCC6q1duuO/madeleines",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/vUT5LFCs7oaCC6q1duuO/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/_nuxt/",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/terms",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/privacy",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/legalNotice",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/3vUMcEv1TsdeeVm1ytlW/pourquoi-les-pepites-de-chocolat-tombent-au-fond-du-gateau",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/3vUMcEv1TsdeeVm1ytlW/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/ipOfbqARszl28RUtsRhH/comment-conserver-sa-brioche",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/qY3iNBySU2q9pDQZOkwO/comment-reussir-ses-crepes-mes-secrets",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/qmR839azeKFziEq9G2vd/est-ce-que-la-peau-du-kiwi-se-mange",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/ss5YNdExGDChsryylpA9/pourquoi-ajouter-une-pincee-de-sel-en-patisserie",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/zdc80uKzvtuvZPaXq3WC/comment-conserver-les-pommes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/lYu1rsUZHCQPhgDsIN3N/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/lYu1rsUZHCQPhgDsIN3N/cookies-americain",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/xGokEXTVmTbx3AQma0W4/pourquoi-laisser-sa-pate-a-crepes-reposer",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/articles/pourquoi-laisser-sa-pate-a-crepes-reposer",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/aRL2AE6Tc2Oy7wMgDLYB/tarte-aux-pommes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/N1UE2ivq6hTjZHhepYaW/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/niI9MwrCvvio67TMfiCw/pancakes-a-la-banane",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/2XdJow4iE5FRnXkTQOKp/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/2XdJow4iE5FRnXkTQOKp/cookies-aux-flocons-davoines-beurre-de-cacahuetes-et-chocolat",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/qcnTAZm5RRGE3t93Z3JK/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/qcnTAZm5RRGE3t93Z3JK/gateau-au-chocolat-et-mascarpone-de-cyril-lignac",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/V5Z4eyEHkRdvhYOFLDEs/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/shcTI3hac6Evu6xgVpYg/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/shcTI3hac6Evu6xgVpYg/tiramisu-recette-classique",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/LPiVRQIYTCu8q7GbFfax/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/LPiVRQIYTCu8q7GbFfax/tartes-aux-myrtilles-et-aux-mures",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/b8kEGZvCvuRGxrfvZtZQ/invisible-aux-pommes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/fUUmSmVKxhP4VbFELg6N/cookies-aux-pepites-de-chocolat",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/7cIWdGHcSXqGYCAQEhfV/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/7cIWdGHcSXqGYCAQEhfV/gateau-aux-pommes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/wCTAqTMIDVkoeLLItWVr/index.html",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/wCTAqTMIDVkoeLLItWVr/dattes-au-chocolat-et-fourrees-au-beurre-de-cacahuete",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/recipe/CnvLsEzquiX2oe1VpPUf/crumble-aux-pommes",
    component: component_45stubEryUQn5bCe
  },
  {
    name: component_45stubEryUQn5bCeMeta?.name,
    path: "/article/nFAbjqFFtjjlAlMThRKZ/comment-dorer-une-brioche-option-vegan",
    component: component_45stubEryUQn5bCe
  }
]